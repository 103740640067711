<script lang="ts" setup>
import useNumberInput from '@/composables/useNumberInput'
const { skrNumberOnKeydown, skrNumberOnPaste } = useNumberInput()

defineProps({
  value: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (event: 'input', state: string): void
  (event: 'onEnter'): void
}>()
</script>

<template>
  <div class="tandialog">
    <div class="tandialog-input-wrapper">
      <v-text-field
        :value="value"
        class="tandialog-input"
        type="text"
        maxlength="6"
        inputmode="numeric"
        filled
        data-cy="tan_input"
        @paste.prevent="emit('input', skrNumberOnPaste($event)?.substring(0, 6) ?? '')"
        @keydown="skrNumberOnKeydown"
        @keydown.enter="emit('onEnter')"
        @input="emit('input', $event.substring(0, 6))"
      />
    </div>
  </div>
</template>

<style lang="sass">
$char-w: 1ch
$gap: .5*$char-w
$n-char: 6
$in-w: $n-char*($char-w + $gap)

.tandialog
  display: flex
  justify-content: center
  &-input-wrapper
    .theme--light.v-input .v-text-field__slot
      padding: 1.5ch 0.25ch

      input
        display: block
        border: none
        padding: 0
        width: $in-w
        max-height: none
        height: 2ch
        color: $c-text-alt
        background: repeating-linear-gradient(90deg, $c-text-alt 0, $c-text-alt $char-w, transparent 0, transparent $char-w + $gap) 0 100%/ #{$in-w - $gap} 2px no-repeat
        font: 3ch droid sans mono, consolas, monospace
        font-weight: 600
        letter-spacing: $gap
</style>
